import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useCategories } from './CategoriesContent';
import Loading from '../Loading';
import { Helmet } from 'react-helmet';

function Categories() {
    const { categories } = useCategories();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [expandedId, setExpandedId] = useState(null);
    const [showDescription, setShowDescription] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (categories.length > 0) {
            setIsLoading(false);
        }
    }, [categories]);

    const toggleExpand = (id) => {
        if (expandedId === id) {
            setExpandedId(null);
            setShowDescription(false);
        } else {
            setExpandedId(id);
            setShowDescription(true);
        }
    };

    const navigateToCategory = (e, id) => {
        e.stopPropagation();
        navigate(`/tea-house/categories/${id}/productsOfCategory`);
    };

    if (isLoading) {
        return <Loading />;
    }

    const descriptOrdered = (description) => {
        return description.split('/n').map((line, index) => (
            <span key={index}>
                {line[line.length - 2] !== '-' ? <>{line}<br />&nbsp;&nbsp;</> : <>&nbsp;&nbsp;{line}<br/></>}
            </span>
        ));
    };

    return (
        <>
            <Helmet>
                <meta name="description" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
                <meta name="keywords" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
            </Helmet>
            <CategoriesDiv>
                {categories.map((category) => (
                    <CategoryContainer
                        key={category.id}
                        expanded={expandedId === category.id}
                        onMouseEnter={() => !isMobile && toggleExpand(category.id)} // Hover for large screens
                        onMouseLeave={() => !isMobile && setExpandedId(null)}     // Hover for large screens
                        onClick={() => isMobile && toggleExpand(category.id)}     // Click for small screens
                    >
                        <CategoryUL>
                            <CategoryLI>
                                {category.title}
                                <img src={category?.imageURL} alt={category?.title} />
                            </CategoryLI>
                        </CategoryUL>
                        {expandedId === category.id && showDescription && (
                            <CategoryContent>
                                <CategoryDescription>
                                    {descriptOrdered(category.description)}
                                </CategoryDescription>
                                <LinkToCategory onClick={(e) => navigateToCategory(e, category?.id)}>Перейти в раздел</LinkToCategory>
                            </CategoryContent>
                        )}
                    </CategoryContainer>
                ))}
            </CategoriesDiv>
        </>
    );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CategoriesDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 160px;
`;

const CategoryContainer = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 10px;
    border: 2px solid beige;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
    box-shadow: ${({ expanded }) => expanded ? '0 8px 16px rgba(0,0,0,0.2)' : 'none'};
    &:hover {
        box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    }
    @media(max-width: 768px) {
        max-width: 270px;
    }
`;

const CategoryUL = styled.ul`
    list-style: none;
    margin: 0;
    padding: 10px;
    background: white;
`;

const CategoryLI = styled.li`
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
    color: #1b5e20;
    font-weight: 900;
    text-align: center;
    cursor: pointer;
    padding: 10px 20px;
    background: linear-gradient(120deg, #e8f5e9, #c8e6c9);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    @media(max-width: 768px){
        justify-content: space-between;
        font-size: 14px;
        color: green;
        font-weight: 600;
    }
    img{
        width: 15%;
        height: 15%;
        border-radius: 50%;
        margin-left: auto;
        @media(max-width: 768px){
            width: 25%;
            height: 25%;
        }
    }
    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    }

    &::before {
        content: '';
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
        background-image: radial-gradient(circle, #a5d6a7, #66bb6a, #388e3c);
        transition: all 0.7s ease-out;
        z-index: -1;
        opacity: 0;
    }

    &:hover::before {
        transform: rotate(20deg);
        top: -30%;
        left: -30%;
        opacity: 1;
    }

    &:active {
        transform: translateY(-1px);
        box-shadow: 0 5px 10px rgba(0,0,0,0.15);
    }
`;

const CategoryContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 300px;
    background-color: white;
    padding: 20px;
    @media(max-width: 768px){
        height: 190px;
    }
`;

const CategoryDescription = styled.div`
    flex-grow: 1;
    color: green;
    font-size: 19px;
    text-align: justify;
    animation: ${fadeIn} 0.5s ease-out;
    padding-right: 25px;
    padding-left: 25px;
    box-sizing: border-box;
    overflow-y: auto;
    @media (max-width: 768px){
        font-size: 4.5vw;
        text-align: left; 
        text-indent: 10px;
        padding-right: 5px;
        padding-left: 5px;
    }
`;

const LinkToCategory = styled.div`
    text-align: center;
    font-weight: 800;
    color: red;
    font-size: 17px;
    margin-top: 15px;
`;

export default Categories;



