import React, { useEffect, useRef, useState } from 'react';
import videoFile from '../video.mp4';
import { useLocation } from 'react-router-dom';
import Loading from '../Loading';
import { AboutContainer, Section, Title, Content } from './About';
import styled from 'styled-components';

function About() {
  const location = useLocation();
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true); // State to track play/pause status

  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
  };

  // Toggle Play/Pause
  const handlePlayPause = () => {
    const videoElement = videoRef.current;
    if (isPlaying) {
      videoElement.pause();
    } else {
      videoElement.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 320) {
        videoRef.current.muted = true;
      } else {
        videoRef.current.muted = false;
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove event listener
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleCanPlay = () => {
      setIsLoading(false);
    };

    const handleError = () => {
      setIsLoading(false);
    };

    videoElement.addEventListener('canplay', handleCanPlay);
    videoElement.addEventListener('error', handleError);

    // Set video volume lower
    videoElement.volume = 0.3;

    return () => {
      videoElement.removeEventListener('canplay', handleCanPlay);
      videoElement.removeEventListener('error', handleError);
    };
  }, []);

  useEffect(() => {
    if (location.hash) {
      const elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    }

    // Setup autoplay for Safari as well
    if (isSafari() && videoRef.current) {
      videoRef.current.play().catch(error => {
        console.log("Autoplay was prevented:", error);
      });
    }
  }, [location]);

  return (
    <AboutContainer>
      {isLoading && <Loading />}
      <Section>
        <Title>Немного О Нас</Title>
        <Content>
        Чайный магазин "Tea House TLV" - не просто место для покупки чая онлайн. Это настоящее чайное пространство, где каждый может найти для себя что-то особенное. Наша история в Израиле началась в 2020 году, и на сегодняшний день мы стали любимым местом для многих ценителей китайского чая. Ассортимент, всегда представленный в наличии, включает все популярные категории китайских чаев, от шу и шен пуэров до светлых и темных улунов. Также у нас имеется широкий выбор чайной посуды и инструментов для чайной церемонии.
        <br></br>
        В чайном пространстве "Tea House TLV" вы не только сможете получить свой заказ лично и, при желании, выбрать чай или посуду, но и расслабиться, выпив чая со своими друзьями в приятной и непринужденной обстановке. Также мы предлагаем выездные чайные церемонии. У нас чай становится не просто напитком, а источником радости и вдохновения.
        <br></br>
        Чайное пространство находится по адресу: Menora 28, Tel Aviv. 
        Сообщайте пожалуйста заранее, когда планируете нас посетить.
        </Content>
        <Video
          ref={videoRef}
          autoPlay
          loop
          playsInline
          src={videoFile}
          type="video/mp4"
        >
          Your browser is not supported!
        </Video>

        <Button onClick={handlePlayPause}>
          {isPlaying ? 'Pause' : 'Play'}
        </Button>
      </Section>
    </AboutContainer>
  );
}

const Video = styled.video`
  width: 100%;
  border-radius: 8px;
  height: 400px;
  margin: auto;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 230px;
  }
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 16px;
  }
`;

export default About;


