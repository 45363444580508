import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Cart from '../Cart/Cart';
import { useNavigate } from 'react-router-dom';
import CartPopup from '../Cart/CartPopup';

function MovingNavbar() {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const subMenuRef = useRef(null);
  const infoNavItemRef = useRef(null);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible]);

  const navigateToHome = () => {
    navigate('/');
  };

  const navigateToAbout = () => {
    setShowSubMenu(!showSubMenu);
  };

  const handleProducts = () => {
    navigate('/tea-house/allproducts');
  };

  const handleNavigateToEvents = () => {
    navigate('/tea-house/allevents');
  };

  const navigateTo = (path) => {
    navigate(path);
    setShowSubMenu(false); // Hide the sub-menu when navigating
  };

  // Close the sub-menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        subMenuRef.current &&
        !subMenuRef.current.contains(event.target) &&
        infoNavItemRef.current &&
        !infoNavItemRef.current.contains(event.target)
      ) {
        setShowSubMenu(false);
      }
    };

    // Add event listener to detect clicks outside
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [subMenuRef, infoNavItemRef]);

  return (
    <Navbar visible={visible}>
      <NavItem onClick={navigateToHome}>Главная</NavItem>
      <NavItem ref={infoNavItemRef} onClick={navigateToAbout}>Информация</NavItem>
      {showSubMenu && (
        <SubMenu ref={subMenuRef}>
          <SubMenuItem onClick={() => navigateTo('/about-us')}>О Нас</SubMenuItem>
          <SubMenuItem onClick={() => navigateTo('/about-delivery')}>Оплата и Доставка</SubMenuItem>
          <SubMenuItem onClick={() => navigateTo('/about-bonuses')}>Бонусы и скидки</SubMenuItem>
          <SubMenuItem onClick={() => navigateTo('/about-brewing')}>Как правильно заваривать чай</SubMenuItem>
        </SubMenu>
      )}
      <NavItem onClick={handleProducts}>Продукция</NavItem>
      <NavItem onClick={handleNavigateToEvents}>Эвенты</NavItem>
      <CartDiv onClick={togglePopup}>
        <Cart />
        {showPopup && <CartPopup close={togglePopup} />}
      </CartDiv>
    </Navbar>
  );
}

const Navbar = styled.nav`
  position: fixed;
  width: 100%;
  background: #eae3d1;
  color: #fff;
  transition: top 0.3s;
  top: ${({ visible }) => (visible ? '0' : '-65px')};
  display: flex;
  margin-top: 5rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  z-index: 100;

  @media (max-width: 768px) {
    height: 1.8rem;
    margin-top: 4.4rem;
    top: ${({ visible }) => (visible ? '0' : '-45px')};
  }
`;

const NavItem = styled.div`
  color: #2e4f24;
  cursor: pointer;
  font-size: 1.2rem;
  margin: 1rem;
  transition: all 0.2s;
  &:hover {
    border-top: 2px solid #2e4f24;
    border-bottom: 2px solid #2e4f24;
  }
  @media (max-width: 768px) {
    font-size: 0.7rem;
    margin: 4px;
  }
`;

const SubMenu = styled.div`
  position: absolute;
  background: #eae3d1;
  top: 3rem; /* Adjust based on your layout */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  flex-direction: column;
`;

const SubMenuItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  color: #2e4f24;
  &:hover {
    background: #f4f1ea;
    border-radius: 4px;
  }
`;

const CartDiv = styled.div`
  margin-left: 75px;

  @media (max-width: 768px) {
    width: 10%;
    margin: 10px 0;
  }
`;

export default MovingNavbar;


