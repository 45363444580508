import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import axios from 'axios';
import baseUrl from '../../baseURL';
import { useCart } from '../Cart/CartContext';
import { Helmet } from 'react-helmet';
import Loading from '../Loading';
function Products() {
  const [productsData, setProductsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToCart } = useCart();

  useEffect(() => {
    const getProducts = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${baseUrl}/tea-house/categories/${id}/productsOfCategory`);
        setProductsData(response.data?.productsOfCategory);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    };
    getProducts();
  }, [id]);

  const navigateToOneProduct = (product) => {
    navigate(`/tea-house/products/${product?.id}`, { state: { product } });
  };

  if (isLoading) {
    return <Loading />;
}
  return (
    <>
    <Helmet>
                    <meta name="description" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
                    <meta name="keywords" content="Выбор чая Выбор китайского чая, китайский набор, столик для чая, столик для китайского чая, чайная продукция, китайская утварь, церемония, китайская чайная, китайская чайная в Израиле, китайская чайная тель-авив, попить чай, попить китайский чай, заказать мероприятие, мероприятие, чайная церемония, китайское место, чай, китайский, набор посуды, набор китайской посуды, Раздел Чая, Чай, Китайский чай, Китайская продукция, Тель-Авив, Израиль" />
            </Helmet>
            <ProductsDiv>
      {productsData?.map((product) => (
        
        <ProductDiv key={product?.id} onClick={() => navigateToOneProduct(product)}>
          <Helmet>
                    <meta name="description" content={`${product?.description}`} />
                    <meta name="keywords" content={`${product?.title}`} />
            </Helmet>
          <Title>{product?.title}</Title>
          <ProductImage src={product?.imageURL} alt="" />
          <ProductDetails>
            <ProductInfo>
              <span className='price'>Цена:</span>
              <Price>&#8362; {product?.price}</Price>
            </ProductInfo>
            <ProductInfo>
              <span className='amount'>Кол-во:</span>
              <Amount>{product?.amount}</Amount>
            </ProductInfo>
          </ProductDetails>
          <Buttons>
            <ButtonGreen onClick={(e) => { e.stopPropagation(); addToCart(product, product?.price); }}>Добавить</ButtonGreen>
          </Buttons>
        </ProductDiv>
      ))}
      
    </ProductsDiv>
    </>
    
  );
}
// Additions and modifications below
const ProductsDiv = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap */
  justify-content: center;
  align-items: center;
  gap: 50px; /* Adds space between the items */
  padding: 50px;
  padding-top: 160px;
  @media(max-width: 768px){
    flex-direction: column;
    padding: 20px;
    padding-top: 150px;
  }
`;
const scaleAnimation = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
`;
const Title = styled.h3`
  font-size: 24px;
  color: green;
  margin-bottom: 10px;
  @media(max-width: 768px){
    font-size: 17px;
  }
`;
const ProductDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 3px solid #eae3d1;
  width: 450px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  cursor: pointer;
  img {
    width: 260px;
    @media(max-width: 768px){
        width: 120px;
    }
  }
  &:hover {
    animation: ${scaleAnimation} 0.3s ease forwards;
  }
  @media(max-width: 768px){
    width: 210px; 
    margin-bottom: 20px;
  }
`;
const ButtonGreen = styled.button`
  width: 400px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: green;
  border-radius: 5px;
  border: none;
  height: 45px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0); 
  @media(max-width: 768px){
    width: 190px;
    height: 30px; 
  }
  &:hover {
    transform: scale(1.05); 
    background-color: #3cb371; 
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); 
  }
  
  &:active {
    transform: scale(0.95);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
  }
`;

const ProductImage = styled.img`
  height: auto;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1) rotate(3deg);
  }
`;

const ProductDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  .amount{
    margin-left: 20px;
    font-size: 16px;
    @media(max-width: 768px){
      margin-left: 10px;
      font-size: 11px;
    }
  }
  .price{
    font-size: 16px;
    @media(max-width: 768px){
      font-size: 11px;
    }
  }
  
`;

const Price = styled.span`
  margin-left: 5px;
  color: #4CAF50; 
  font-weight: bold;
  font-size: 1.2rem; 
  background-color: #f0f0f0; 
  padding: 3px 8px;
  border-radius: 5px; 
  box-shadow: 0px 2px 4px rgba(0,0,0,0.1); 
  transition: transform 0.3s ease;
  @media(max-width: 768px){
      font-size: 0.6rem;
      padding: 2px 6px;
  }
  &:hover {
    transform: scale(1.1); 
    background-color: #e8f5e9; 
  }
`;

const Amount = styled.span`
  margin-left: 5px;
  font-weight: bold;
  font-size: 1.2rem; 
  color: #4CAF50; 
  background-color: #f0f0f0; 
  padding: 3px 8px; 
  border-radius: 5px; 
  box-shadow: 0px 2px 4px rgba(0,0,0,0.1); 
  transition: all 0.3s ease;
  
  @media(max-width: 768px){
      font-size: .6rem;
      padding: 2px 5px;
  }
  &:hover {
    transform: scale(1.05); 
    background-color: #e8f5e9; 
  }
`;


const Buttons = styled.div`
  display: flex;
  flex-direction: column;
`;





export default Products;
